.news-thumbnail {
	padding: 16px;
}

.news-content-preview {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.writer-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}

.writer-pic {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid darkslategrey;
}

.writer-name {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
}

.state-buttons-container {
    display: flex;
    justify-content: flex-end;
}

.news-title-thumb {
    overflow: hidden;
	text-overflow: ellipsis;
    margin: 0;
    display: flex;
}

.news-title-thumb-container {
    display: flex;
    align-items: center;
}

.headline-container {
    margin-bottom: 12px;
}

.publish-time {
    text-align: right;
}


.publish-state {
    margin-right: 8px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.publish-state.published {
    color: #2ecc71;
    border: 1px solid #2ecc71;
}

.publish-state.unpublished {
    color: #e74c3c;
    border: 1px solid #e74c3c;
}
