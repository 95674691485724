.thead-title {
    text-transform: uppercase;
    text-align: center;
}

.thead-title.clickable {
    cursor: pointer;
}

.members-list-table {
    margin-top: 24px;
    margin-bottom: 0px;
}

.members-list-table.loading {
    display: flex;
    justify-content: center;
}

.solde {
    font-weight: bold;
}

.solde.due {
    color: #e74c3c;
}

.solde.bonus {
    color: #27ae60;
}

#member-list > tbody > tr > th > .fullname-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

#member-list > tbody > tr > td > .btn {
    padding-top: 0px;
    padding-bottom: 0px;
}

#member-list > tbody > tr > td {
    text-align: center;
}


.member-profile-picture {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 12px;
}


.badge {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999px
  }
  
  .badge.badge-default {
    background-color: #B0BEC5
  }
  
  .badge.badge-primary {
    background-color: #2196F3
  }
  
  .badge.badge-secondary {
    background-color: #323a45
  }
  
  .badge.badge-success {
    background-color: #64DD17
  }
  
  .badge.badge-warning {
    background-color: #FFD600
  }
  
  .badge.badge-info {
    background-color: #29B6F6
  }
  
  .badge.badge-danger {
    background-color: #ef1c1c
  }
  
  .badge.badge-outlined {
    background-color: transparent
  }
  
  .badge.badge-outlined.badge-default {
    border-color: #B0BEC5;
    color: #B0BEC5
  }
  
  .badge.badge-outlined.badge-primary {
    border-color: #2196F3;
    color: #2196F3
  }
  
  .badge.badge-outlined.badge-secondary {
    border-color: #323a45;
    color: #323a45
  }
  
  .badge.badge-outlined.badge-success {
    border-color: #64DD17;
    color: #64DD17
  }
  
  .badge.badge-outlined.badge-warning {
    border-color: #FFD600;
    color: #FFD600
  }
  
  .badge.badge-outlined.badge-info {
    border-color: #29B6F6;
    color: #29B6F6
  }
  
  .badge.badge-outlined.badge-danger {
    border-color: #ef1c1c;
    color: #ef1c1c
  }