.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
}

.member-profil-full-container {
    height: 100%;
}

.profile-infos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.member-profile-picture-large {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 16px;
}

.profile-headline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-main-informations {
    width: 90%;
}

.profile-data {
    text-align: justify;
}

.profile-data-key {
    font-weight: bolder;
}

.profile-section {
    border-bottom: solid 1px lightgray;
    margin-bottom: 12px;
}

.profile-toolbar {
    display: flex;
    justify-content: right;
}

.btn-toolbar-container {
    text-align: right;
}

.profile-toolbar > .btn {
    margin-right: 16px;
}

.state-container {
    margin-bottom: 16px;
}