.members-list {
    min-height: 500px;
}

.search-members-box {
    box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12) , 0 3px 6px 0 rgba(0,0,0, 0.12);
    border-radius: 5px;
    border: solid 1px lightgray;
    margin-left: 12px;
    padding-left: 8px;
    display: flex;
    align-items: center;  
}

.search-members-box > i {
    margin-right: 8px;
    color: grey;
}

.search-members-input {
    width: stretch;
    border: 0px;
    font-size: 14px;
    color: #3c4257;
    height: 25px;
    width: 100%;
    outline: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.buttons-container {
    text-align: right;
}

.buttons-container > .btn {
    margin-right: 12px;
    margin-left: 12px;
}

.member-count {
    margin-bottom: 0px;
}

.member-count-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}