.login-page {
	width: 100%;
	height: 100vh;
	background: url("../../../public/background.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100vh;
}

@media only screen and (min-width: 768px) {
	.login-page {
		padding-right: 64px;
	}
}

.login-form-container {
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.login-form {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-title {
	color: white;
	margin: 32px;
	font-weight: 100;
}

.login-page .form-group > label {
	color: white;
	font-weight: bolder;
}

.connection-button-container {
	margin-top: 2em;
}

.error-text {
	color: #e74c3c;
	font-weight: bolder;
}

.btn-title {
	margin-left: 8px;
}