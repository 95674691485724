#admin_page_container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    height: -moz-available;
    height: -webkit-fill-available;
	height: stretch;
	background-color: #e3e8ee;
}

#admin_page_container #sidenav_container {
    height: 100vh;
}

#admin_page_container #sidenav_inner_container {
    background-color: rgba(0, 0, 0, 0.1);
    height: -moz-available;
    height: -webkit-fill-available;
    height: stretch;
    padding: 1em;
    height: 100%;
}

#admin_page_container #content {
    padding: 1em;
    flex: 4;
    background-color: #e3e8ee;
    color: #3c4257;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 100vh;
}
