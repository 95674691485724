.toast {
	min-width: 350px;
}

.toast .close {
	color: white;
}

.toast.error {
	background-color: #e95a4b;
	color: white;
}

.toast.error .toast-header {
	background-color: #e74c3c;
	color: white;
}

.toast.success {
	background-color: #39d27a;
	color: white;
}

.toast.success .toast-header {
	background-color: #2ecc71;
	color: white;
}