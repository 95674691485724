#sidenav .btn {
	width: 100%;
	margin-bottom: 12px;
	overflow: hidden;
	display: inline;
	white-space: nowrap;
}

#sidenav.expanded .button-title {
	visibility: visible;
	display: inline;
}

#sidenav .button-title {
	visibility: hidden;
	display: none;
	padding-left: 8px;
}


.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0 transparent;
}

#sidenav .btn.btn-outline-primary {
	color: #95a5a6;
	border-color: #95a5a6;
}

#sidenav .btn.btn-outline-primary.active {
	color: white;
	border-color: #007bff;
}

#sidenav .btn.btn-outline-primary:hover {
	color: white;
	background-color: #95a5a6;
}

#sidenav .btn.btn-outline-primary.active:hover {
	color: white;
	background-color: #007bff;
	border-color: #007bff;
}

#sidenav .btn-outline-primary:not(:disabled):not(.disabled):active {
	color: white;
	background-color: #95a5a6;
	border-color: lightgrey;
}
