#sidenav #links_list li a {
    padding: 0;
    color: black;
}

#sidenav #links_list {
    padding-top: 148px;
    transition: padding-top 0.35s ease-in-out;
}

#sidenav.expanded #links_list {
    padding-top: 0px;
}

#sidenav {
    width: 50px;
    transition: width 0.35s ease-in-out;
    height: 100%;
}

#sidenav #account {
    padding-top: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.25s ease-out 0.35s; 
    opacity: 0; 
    height: 0;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
}

#sidenav.expanded #account {
    opacity: 1;
    overflow: visible;
    height: auto;
}

#sidenav #header {
    text-align: center;
    margin-bottom: 2rem;
}

#sidenav #header #title {
    border-bottom: 1px gray solid;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

#sidenav .profile-picture {
    width: 100%;
    transition: width 0.35s ease-in-out;
    border-radius: 50%;
}

#sidenav.expanded .profile-picture {
    width: 100%;
}

#sidenav.expanded {
    width: 200px;
}

#sidenav .navbar-items-end {
    position: absolute;
    bottom: 0;
}
