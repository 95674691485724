
.pontoon-name {
	text-align: center;
	padding-top: 16px;
}

.pontoons-container {
	padding: 42px;
	overflow: auto;
	align-items: end;
}

.pontoon-place {
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.pontoon-place.is-occupied {
	height: 42px;
}

.is-quatway {
	background-color: #2980b9;
}

.pontoon-place.is-quatway {
	background-color: #2980b9;
	height: 42px;
}

.pontoon-place > .owner-name {
	font-size: 12px;
	margin: 0px;
}

.pontoon-place > .boat-name {
	font-size: 12px;
	font-weight: bolder;
	margin: 0px;
}

.pontoon-place.left {
	border-left: solid 1px #2980b9;
	border-top: solid 0.5px #2980b9;
	border-bottom: solid 0.5px #2980b9;
}

.pontoon-place.right {
	border-right: solid 1px #2980b9;
	border-top: solid 0.5px #2980b9;
	border-bottom: solid 0.5px #2980b9;
}

.pontoon {
	width: 500px;
}

.pontoons-list {
	padding: 16px;
}

.place-id {
	font-size: 13px;
	margin: 0;
	padding: 0;
	font-weight: bolder;
	color: white;
	height: 25px;
}

.place-id.left {
	width: 50%;
}

.place-id.right {
	width: 50%;
	text-align: right;
}

.quatway-center {
	display: flex;
	padding-left: 3px;
	padding-right: 3px;
	align-items: center;
}